<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Enumerators</h1>
      <div class="caption green--text">new,edit,password,status,upload</div>
      <v-divider class="my-2"></v-divider>
    </div>
    <v-row dense>
      <v-col cols="12" class="">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="tableData"
            sort-by="id"
            class="elevation-1"
            :loading="tableLoading"
            loading-text="Loading... Please wait"
            fixed-header
            height="350px"
            :no-data-text="tableNoDataText"
          >
            <template v-slot:item.first_name="{ item }">
              <span>{{ item.first_name | tableLen30 }}</span>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-dialog v-model="dialog" max-width="600px">
                  <template v-slot:activator="{ on, attrs }" >
                    <v-btn
                      color="primary"
                      dark
                      class="ma-1 px-2"
                      v-bind="attrs"
                      outlined
                      v-on="on"
                    >
                      Add Enumerator
                    </v-btn>
                    <v-btn
                      color="primary"
                      dark
                      outlined
                      class="ma-1 px-2"
                      @click="openFileDialog"
                    >
                      Upload Excel file
                    </v-btn>
                  </template>
                  <v-card>
                    <v-toolbar color="green" dark dense flat>
                      <v-toolbar-title class="text-h5">
                        {{ formTitle }}
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-form
                      ref="form"
                      v-model="valid"
                      lazy-validation
                    >
                      <small
                        class="pa-2 grey--text"
                      >*indicates required field</small><br/>
          
                      <v-container>
                        
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="first_name"
                              label="First name*"
                              required
                              :rules="nameRules"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="surname"
                              label="Surname*"
                              required
                              :rules="nameRules"
                            ></v-text-field>
                          </v-col>
                          <v-col v-if="editedIndex < 1" cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="phone"
                              :rules="phoneRules"
                              :counter="11"
                              label="Phone Number *"
                              maxlength="11"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="email"
                              :rules="emailRules"
                              label="Email *"
                              required
                            ></v-text-field>
                          </v-col>
                          <!--<v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-menu
                              ref="menu"
                              v-model="menu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="transaction_date"
                                  label="Transaction date"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  :disabled="formDisabled" 
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="transaction_date"
                                :active-picker.sync="activePicker"
                                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                :min="min_date"
                                @change="saveDate"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>-->
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="designation"
                              label="designation*"
                              required
                              :rules="nameRules"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-select
                              v-model="state"
                              return-object
                              :items="state_arr"
                              item-text="title"
                              item-value="id"
                              label="Resident State*"
                              :rules="[v => !!v || 'State is required']"
                              required
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="green darken-1" text @click="close">
                        Close
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        outlined
                        :loading="formBtnLoader"
                        @click="postData()"
                      >
                        Submit Data
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialog2" max-width="600px">
                  <v-card>
                    <v-toolbar color="green" dark dense flat>
                      <v-toolbar-title class="text-h5">
                        Enumerator Authorization
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-form
                      ref="form2"
                      v-model="valid2"
                      lazy-validation
                    >
                      <small
                        class="pa-2 grey--text"
                      >*indicates required field</small><br/>
          
                      <v-container>
                        
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="first_name"
                              label="First name*"
                              required
                              :rules="nameRules"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="surname"
                              label="Surname*"
                              required
                              :rules="nameRules"
                            ></v-text-field>
                          </v-col>
                          <v-col v-if="editedIndex < 1" cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="phone"
                              :rules="phoneRules"
                              :counter="11"
                              label="Phone Number *"
                              maxlength="11"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="email"
                              :rules="emailRules"
                              label="Email *"
                              required
                            ></v-text-field>
                          </v-col>
                          <!--<v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-menu
                              ref="menu"
                              v-model="menu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="transaction_date"
                                  label="Transaction date"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  :disabled="formDisabled" 
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="transaction_date"
                                :active-picker.sync="activePicker"
                                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                :min="min_date"
                                @change="saveDate"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>-->
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="designation"
                              label="designation*"
                              required
                              :rules="nameRules"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-select
                              v-model="state"
                              return-object
                              :items="state_arr"
                              item-text="title"
                              item-value="id"
                              label="Resident State*"
                              :rules="[v => !!v || 'State is required']"
                              required
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="green darken-1" text @click="close">
                        Close
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        outlined
                        :loading="formBtnLoader"
                        @click="postData()"
                      >
                        Submit Data
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!--upload-->
                <v-dialog v-model="dialogUpload" max-width="600px">
                  <v-card>
                    <v-toolbar color="green" dark dense flat>
                      <v-toolbar-title class="text-h5">
                        File Upload
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-form
                      ref="form3"
                      v-model="valid3"
                      lazy-validation
                    >
                      <small
                        class="pa-2 grey--text"
                      >*indicates required field</small><br/>
          
                      <v-container>
                        
                        <v-row>

                          <v-col 
                            v-if="fileProgress" 
                            cols="12"
                            sm="12"
                            md="12"
                            class="py-0"
                          >
                            <div>
                              <v-progress-linear
                                v-model="fileProgress"
                                color="light-blue"
                                height="25"
                                reactive
                              >
                                <strong>{{ fileProgress }} %</strong>
                              </v-progress-linear>
                            </div>
                          </v-col>

                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="py-0"
                          >
                            <v-file-input
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              label="File input"
                              @change="selectFile"
                            ></v-file-input>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                              class="py-0"
                            >
                              <v-select
                                v-model="state_upload"
                                return-object
                                :items="state_arr"
                                item-text="title"
                                item-value="id"
                                label="Resident State*"
                                :rules="[v => !!v || 'State is required']"
                                required
                              ></v-select>
                            </v-col>
                          </v-col></v-row>
                      </v-container>
                    </v-form>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="green darken-1" text @click="close">
                        Close
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        outlined
                        :loading="formBtnLoader"
                        @click="doUpload()"
                      >
                        Upload File
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!--upload-->
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }" >
              <v-icon class="mr-2" @click="editEnumerator(item)" >
                mdi-file-document-edit
              </v-icon> 
              <v-icon class="mr-2" @click="editAuthorization(item)" >
                mdi-calendar-range
              </v-icon>
              <v-icon class="mr-2" @click="editDate(item)" >
                mdi-lock
              </v-icon>  
            </template>
            <template v-slot:no-data>
              <div class="caption">
                This table has no record! Try reloading it
              </div>
              <v-btn color="primary" outlined small @click="getTableData">
                Reload Data
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout
  },
  data() {
    return {
      memberData:{},
      currentLOBDetails:{},
      prePostData:{},
      pageData:{},
 
      dialog: false,
      dialog2: false,
      dialogUpload:false,
      tableLoading: true,
      singleSelect: true,
      tableNoDataText: 'Thus table has no record!',

      snackbar: false,
      snackbartext: '',

      enumeratorId: '',
      first_name: '',
      surname: '',
      phone:'',
      email:'',
      designation:'',
      state:{},
      state_arr:[],

      state_upload:{},
      currentFile:undefined,
      fileProgress:0,
      uploadMessage:'',

      activePicker: null,
      transaction_date: null,
      menu: false,
      min_date:'',

      verifyBtnLoader: false,
      formDisabled: true,
      formBtnLoader: false,
      formDisabled2: true,
      formBtnLoader2: false,
    
      valid: false,
      valid2:false,
      valid3:false,
      filingBtnLoader: false,
      phoneRules: [
        (v) => !!v || 'Phone Number is required',
        (v) => /^\d{11}$/.test(v) || 'Phone number be must be valid',
        (v) => (v && v.length <= 11) || 'Phone Number must be 11 figures'
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length >= 1) || 'Name must be greater than 2 characters'
      ],
      dateRules: [
        (v) => !!v || 'Date is required',
        (v) => (v  &&  Date.parse(v.replaceAll(' ',''))) || 'Date must be valid'
      //(v) => (v  &&  v instanceof Date && !isNaN(v)) || 'Date must be valid'
      ],
      numberRules: [
        (v) => !!v || 'Tonnage is required',
        (v) => (!isNaN(parseFloat(v))) || 'Tonnage must be a number',
        (v) => (v && v > 0 ) || 'Tonnage must greater than zero'
      ],

      headers: [
        {
          text: 'First Name',
          align: 'start',
          sortable: false,
          value: 'first_name'
        },
        { text: 'Surname', value: 'surname' },
        { text: 'Phone', value: 'phone' },
        { text: 'Email', value: 'email' },
        { text: 'Status', value: 'status' },
        { text: 'Start Date', value: 'startDate' },   
        { text: 'Stop Date', value: 'stopDate' },   
        { text: 'Modify', value: 'actions', sortable: false }
      ],
      tableData: [],
      editedIndex: -1
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? 'Add New Enumerator\'s Record'
        : 'Modify Existing Record'
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')

    //console.log(this.prePostData)
    this.getTableData()
    this.getPageData()
  },
  mounted() {},
  methods: {
    isEditOk: function(item) {
      if (item.designation === 'Admin') {
        return true
      }  else {
        return false
      }
    },
    saveDate (date) {
      this.$refs.menu.save(date)
    },
    getTableData: function () {
      const postData = this.prePostData

      postData.state = 'all'
      const url = httpServ.getFarmerEnumerationtUrl('/enumeration/admin/getEnumeratorsDataTables')
      const resp = httpServ.postFetch(
        url,
        this.prePostData
        //this.memberData.jwt
      )

      resp.then((result) => {
        this.tableData = result.data
      })
      this.tableLoading = false
    },
    getPageData: function () {
      const url = httpServ.getFarmerEnumerationtUrl('/enumeration/admin/formOptions/enumerator')
      const resp = httpServ.makeRequest(url, 'GET')

      resp.then((result) => {
        this.state_arr = result.data.states
      })
    },
    getNewData: function () {
      const data = {
        status: 'new',
        first_name: this.first_name,
        surname: this.surname,
        email: this.email,
        phone:this.phone,
        designation:this.designation,
        state: this.state.title
      }

      //console.log(data)

      return data
    },
    getUpdatedData: function () {
      const data = {
        status: 'update',
        first_name: this.first_name,
        surname: this.surname,
        email: this.email,
        phone:this.phone,
        designation:this.designation,
        state: this.state.title,
        enumeratorId:this.enumeratorId
      }

      return data
    },
    postData: function () {
      const postData =
        this.editedIndex > -1 ? this.getUpdatedData() : this.getNewData()

      //validate
      
      if (this.$refs.form.validate()) {
        this.formBtnLoader = true 
    
        const url = httpServ.getFarmerEnumerationtUrl('/enumeration/admin/saveEnumerator')
        const resp = httpServ.postFetch(
          url,
          postData,
          this.memberData.jwt
        )

        resp.then((result) => {
          if (result.status === 200) {
            this.snackbartext = 'Entry was successful!'
            this.snackbar = true
            this.signinBtnText = 'Successful'
            this.close()
            this.getTableData()
          } else if (result.status === 422) {
            
            if (result instanceof SyntaxError) {
              // Unexpected token < in JSON
              //console.log('There was a SyntaxError', result)//checker
              this.snackbartext = result.message
              this.snackbar = true
            } else {
              //console.log('There was an error', result) //Checker
              this.snackbartext = result.message
              this.snackbar = true

            }
            this.formBtnLoader = false
          } else {
            this.snackbartext = result.message 
            this.snackbar = true
            this.formBtnLoader = false
          }
        })
      } else {
        console.log('error')
      }
    },
    editEnumerator(item) {
      //this.editedIndex = 9999999 //this.tableData.indexOf(item)
      
      this.editedIndex = this.tableData.indexOf(item)
      this.first_name = item.first_name
      this.surname = item.surname
      this.email = item.email
      this.phone = item.phone

      this.state = {
        id:item.resident_state,
        title:item.resident_state
      }
      //console.log(this.state)
      this.enumeratorId = item.id
      this.dialog = true
     
      this.formDisabled = false
      this.mode = 'update'
    },
    editAuthorization(item) {
      //this.editedIndex = 9999999 //this.tableData.indexOf(item)
      this.editedIndex = this.tableData.indexOf(item)
      this.dialog2 = true
     
      this.formDisabled = false
      this.mode = 'update'
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        //this.editedItem = Object.assign({}, this.defaultItem)
        this.first_name = ''
        this.surname = ''
        this.phone = ''
        this.email = ''
        this.state.id = 0
        this.editedIndex = -1
        this.verifyBtnLoader = false
        this.formDisabled = true
        this.formBtnLoader = false
        this.mode = 'new'

      })
    },
    openFileDialog: function() {
      this.dialogUpload = true
    },
    closeFileDialog: function() {
      this.dialogUpload = false
    },
    doUpload: function() {
      if (!this.currentFile) {
        this.snackbartext = 'Please select an Excel file!'
        this.snackbar = true

        return
      }
      this.fileProgress = 0
      const url = httpServ.getFarmerEnumerationtUrl('/enumeration/admin/uploadEnumerator')

      httpServ.postUpload(this.currentImage,url, (event) => {
        this.fileProgress = Math.round((100 * event.loaded) / event.total)
      })
        .then((response) => {
          this.snackbartext = 'File uploaded successfully!'
          this.snackbar = true
        })
        .catch((err) => {
          this.progress = 0
          this.snackbartext = 'Could not upload the image! ' + err
          this.currentFile = undefined
          this.snackbar = true
        })
    },
    selectFile: function(file) {
      this.currentFile = file
      this.fileProgress = 0
      this.uploadMessage = ''
    }
    ///////////////
  }
}
</script>

<style scoped>
</style>
