<template>
  <div>
    <Enumerators />
  </div>
</template>

<script>
import Enumerators from '@/nfmp_components/Enumerators.vue'
export default {
  components: {
    Enumerators
  }
}
</script>