<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Farmers Verifier</h1>
      <v-divider class="my-2">Pictures,Dryseeion field,archive field,linkto field</v-divider>
    </div>
    <v-form ref="form" v-model="valid" fast-fail>
      <v-card class="mb-3">
        <v-card-title>Farmers details</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="12" md="6">
              <v-select
                v-model="state"
                return-object
                item-text="title"
                item-value="id"
                :items="state_arr"
                :rules="[(v) => !!v || 'Item is required']"
                label="Farmer's State *"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-select
                v-model="lga"
                return-object
                item-text="title"
                item-value="id"
                :items="lga_arr"
                :rules="[(v) => !!v || 'Item is required']"
                label="Farmer's LGA *"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="phone"
                :rules="phoneRules"
                :counter="11"
                label="Phone Number *"
                maxlength="11"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="identification_id"
                label="National ID Number"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="12" md="6" class="text-center">
          <v-btn
            rounded
            block
            color="primary"
            large
            @click="verify"
          >
            Submit 
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout
  },
  data() {
    return {
      memberData:{},
      currentLOBDetails:{},
      prePostData:{},
      pageData:{},
      snackbar: false,
      snackbartext:'',

      state_arr:[],
      lga_arr:[],
      all_lga_arr:[],
      state:'',
      lga:'',
      identification_id:'',
      phone:'',
      valid:'',

      namesRules: [
        (v) => !!v || 'Name is required',
        (v) =>
          (v && v.length >= 2) ||
        'Name must be greater than 2 characters'
      ],
      selectionsRules: [
        (v) => (v && v.length <= 11) || 'One or more comodities must be selected'
      ],
      phoneRules: [
        (v) => !!v || 'Phone Number is required',
        (v) => /^\d{11}$/.test(v) || 'Phone number be must be valid',
        (v) => (v && v.length <= 11) || 'Phone Number must be 11 figures'
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ]
    }
  },
  watch: {
    state: {
      handler(newvalue) {
        if (newvalue !== undefined) {
          this.lga_arr = this.all_lga_arr.filter(
            (lga) => lga.state_id === this.state.id
          )
        }
      },
      immediate: true
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    httpServ.removeSessionJson('archiveData')
    this.getFormOption()
  },
  mounted() {},
  methods: {
    closeSnackbar: function () {
      snackbar = false
      this.$router.push({ name: 'farmersRegistration' })
    },
    getFormOption: function() {
      const formUrl = httpServ.getFarmerEnumerationtUrl('/enumeration/drySession/formOptions/verifier')
      const resValue2 = httpServ.makeRequest(formUrl, 'GET', { 'enumerator_id':this.memberData[0].id })

      resValue2.then((result) => {
        //console.log('our select is ', result)
        if (result.status === 200) {
          this.state_arr = result.data.states
          this.all_lga_arr = result.data.lgas
        }
      })
    }, 
    getNewData: function() {
      //console.log(this.lead_type)
      const data = {
        state:this.state.title,
        lga:this.lga.title,
        nationalID:this.identification_id,
        phone:this.phone
      }

      return data
    }, 
    verify: function() { 
      const postData =  this.getNewData() 

      //console.log(postData)

      if (this.$refs.form.validate()) {
        const url = httpServ.getFarmerEnumerationtUrl('/enumeration/drySession/verify')
        const resp = httpServ.postFetch(url,postData)

        resp.then((result ) => {
          if (result.status === 200) {
            if (result.message === 'Successful request') {
              httpServ.setSessionJson('archiveData',result.data)
              this.snackbartext = 'This farmer has existing record! Please updated the rest!'
              this.snackbar = true
            } else if (result.message === 'Registered farmer!') {
              this.snackbartext = 'This farmer is already registered!'
              this.snackbar = true
              this.$refs.form.reset()

              return
            } else {
              this.snackbartext = 'This farmer no not have existing records. You have to created a new one!'
              this.snackbar = true
              httpServ.setSessionJson('farmerData',{})
            }
            setTimeout(() => {
              this.$router.push({ name: 'farmersRegistration' })
            }, 4000)
          }  else if (result.status === 422) {
            
            if (result instanceof SyntaxError) {
              // Unexpected token < in JSON
              //console.log('There was a SyntaxError', result)//checker
              this.snackbartext = result.message
              this.snackbar = true
            } else {
              //console.log('There was an error', result) //Checker
              this.snackbartext = result.message
              this.snackbar = true

            }
            this.formBtnLoader = false
          } else {
            this.snackbartext = result.message 
            this.snackbar = true
          }
        })
      }
    }
  }
}
</script>

<style scoped>
</style>
