<template>
  <Layout is-demo>
    <h1 class="text-h4 ms-0 mb-1">Dashboard</h1>
    <v-card class="pa-3 mx-auto" max-width="500">
      <v-card-title>
        <v-divider class="my-1"></v-divider>
        <div class="mx-2">Welcome</div>
        <v-divider class="my-1"></v-divider>
      </v-card-title>
      <v-card-text>
        <v-row justify="center" align="center" dense>
          <v-col cols="12" sm="12" md="6" class="text-center">
            <v-icon
              size="250"
              color="green darken-2"
            >
              mdi-home-account
            </v-icon>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!--<v-alert
      dense
      text
      type="success"
    >You can only operate within your coverage area</v-alert>
    <v-card class="mt-2">
      <v-card-subtitle class="font-weight-bold green--text">Coverage</v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text>State: {{ states }}</v-card-text>
      <v-card-text>Window: {{ window }}</v-card-text>
    </v-card>
    <v-card class="mt-2">
      <v-card-subtitle class="font-weight-bold green--text">Summaries</v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text>Corperative: {{ cooperative }}</v-card-text>
      <v-card-text>Verified Farmers: {{ farmers }}</v-card-text>
    </v-card>-->
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'
//import { Plotly } from 'vue-plotly'

export default {
  components: {
    Layout
  },
  data() {
    return {
      states:'loading',
      window: 'loading',
      cooperative: 'loading',
      farmers: 'loading',

      alterLOBFlag: false,

      someDate:'2023-05-03 00:00:00',
      memberData: {},
      dashboardData: {
        phone: 'loading...',
        email: 'loading...',
        memberId:'loading...',
        surname:'loading...'
      }
    }
  },
  created() {
    
  }
}
</script>

<style scoped>
.v-alert--prominent .v-alert__icon.v-icon {
  font-size: 32px;
  color: green;
}
.v-alert {
  color: green !important;
}
.card-header-text {
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.87);
  padding-bottom: 20px;
}
.d-card-height {
  min-height: 500px !important;
}

::v-deep .v-data-table td {
  height: 20px !important;
  font-size: 14px !important;
}

::v-deep .v-data-table th {
  font-size: 12px !important;
}

.reg-guide-text {
  font-size: 13px !important;
}
</style>
